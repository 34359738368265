<template>
  <!-- 菜单管理页面 -->
  <div class="padding24 box">
    <div v-show="$route.meta.isShow">
      <!-- 顶部 -->
      <div class="top">
        <div class="top_left">
          <a-button icon="plus" type="primary" @click="addMenu">新建菜单</a-button>
        </div>
      </div>
      <a-table :columns="columns" :data-source="tabledData" :pagination="false" :loading="tableLoading" bordered>
        <span slot="displayStatus" slot-scope="displayStatus, record">
          <span>{{ record.displayStatus == 1 ? "是" : "否" }}</span>
        </span>
        <span slot="operation" slot-scope="operation, record, index">
          <a class="margin_right10" @click="editClick(record)">编辑</a>
          <a @click="del(record,index)">删除</a>
        </span>
      </a-table>
    </div>
    <router-view />
  </div>
</template>

<script>
import { Modal } from "ant-design-vue";
import { message } from "ant-design-vue";
import { MenuTreeApi, MenuDeleteApi } from "@/request/api/systemManage";
export default {
  created() {
    this.menuListFn();
  },
    watch: {
        $route (to) {
            if (to.path === "/systemManage/menuManage") {
                this.menuListFn();
            }
        },
    },
  data() {
    return {
      tableLoading: true, // 表格的loading图
      searchVal: "", // 输入框的值
      isDisableSearch: false, // 搜索按钮是否禁用
      columns: [
        {
          title: "菜单名字",
          dataIndex: "name",
          key: "title",
          width: "25%",
        },
        {
          title: "跳转链接",
          dataIndex: "url",
          width: "30%",
          key: "url",
        },
        {
          title: "是否显示",
          dataIndex: "displayStatus",
          width: "10%",
          key: "displayStatus",
          scopedSlots: { customRender: "displayStatus" },
        },
        {
          title: "操作",
          dataIndex: "operation",
          width: "30%",
          key: "operation",
          scopedSlots: { customRender: "operation" },
        },
      ],
      tabledData: [],
    };
  },
  methods: {
    // 添加菜单按钮
    addMenu() {
      this.$router.push(`/systemManage/menuManage/editMenu/0`);
    },
    // 删除的按钮
    del(data, index) {
      let that = this;
      Modal.confirm({
        title: "提示",
        content: "删除后相关数据无法恢复,是否确认删除",
        okText: "确认",
        cancelText: "取消",
        onOk() {
          MenuDeleteApi({ menuNo: data.menuNo }).then((res) => {
            if (res.code === 200) {
              this.tabledData.splice(index, 1)
              message.success("删除成功", 3);
            }
          });
        },
      });
    },
    editClick(data) {
      this.$router.push(`/systemManage/menuManage/editMenu/${data.menuNo}`);
    },
    // 请求菜单列表数据
    menuListFn() {
      MenuTreeApi({}).then(({ code, data }) => {
        if (code === 200) {
          this.tableLoading = false;
          let newData = data.map((item) => {
            return JSON.parse(JSON.stringify(item).replace(/id/g, "key"));
          });
          this.tabledData = newData;
        }
      });
    },
  },
};
</script>
 
<style lang="less" scoped>
.box {
  min-height: 100%;
  background-color: #fff;
  padding-bottom: 56px;
}
.top {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  .top_rigiht {
    span {
      font-size: 16px;
      margin-right: 10px;
      font-size: 600;
    }
    .ant-btn {
      margin-left: 10px;
    }
  }
}
/deep/ .ant-checkbox {
  display: none;
}
/deep/.ant-table-thead > tr > th {
  font-weight: 600;
}
</style>